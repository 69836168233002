import React, { useState, useEffect } from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap'
import logo from '../assets/SalesWave.svg'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa';



const GuestNavbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [sectionToScroll, setSectionToScroll] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const closeNavbar = () => {
    setExpanded(false);
    console.log('clicked');
  };

  const scrollToSection = (sectionId) => {
    if (location.pathname === '/') {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      closeNavbar();
    } else {
      setSectionToScroll(sectionId);
      navigate('/');
      closeNavbar();
    }
  };

  useEffect(() => {
    if (location.pathname === '/' && sectionToScroll) {
      const section = document.getElementById(sectionToScroll);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      setSectionToScroll(null);
    }
  }, [location.pathname, sectionToScroll]);
  return (
    <Navbar expand='lg' data-bs-theme="dark" style={{borderBottom: '1px solid #DADADA33', backgroundColor:'#111111',overflowX:'hidden'}} className=' px-xl-5 px-4 py-4' fixed='top' expanded={expanded} >
        <Navbar.Brand href='/' className='ms-xl-3 '  onClick={closeNavbar}>
            <img src={logo} alt='logo' className='sw-logo'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar}>
        {expanded ? <FaTimes /> : <FaBars />} 
      </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-lg-auto gap-xl-5 gap-lg-3 gap-2 mt-2" id="navbar-example2">

        {/* <Nav.Link href="/trainings" className='nav-link'  onClick={closeNavbar}>Trainings</Nav.Link>
            <Nav.Link href="#section2" className='nav-link'  onClick={closeNavbar}>Our Services</Nav.Link>
            <Nav.Link className='nav-link'  onClick={closeNavbar}> Careers</Nav.Link>
            <Nav.Link className='nav-link'  onClick={closeNavbar}>FAQs</Nav.Link> */}
    
            {/* <Nav.Link href="/trainings" className='nav-link'  onClick={closeNavbar}>Trainings</Nav.Link> */}
            <Nav.Link className='nav-link' onClick={() => scrollToSection('section2')}>Our Services</Nav.Link>
            <Nav.Link className='nav-link'  onClick={() => scrollToSection('section3')}>Our Clients</Nav.Link>
            <Nav.Link className='nav-link'  onClick={() => scrollToSection('section4')}>Our Team</Nav.Link>
        </Nav>
        <Nav className='d-flex me-xl-3' id="navbar-example2">
          <div>
          <Button className='btn btn-primary mt-3 mt-lg-0' onClick={() => scrollToSection('section5')}>Work With Us</Button>
          </div>
      
        
        </Nav>
      
        </Navbar.Collapse>
      
    </Navbar>
  )
}

export default GuestNavbar
