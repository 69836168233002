
import { createContext, useContext, useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';





export const AppContext = createContext();


export const AppContextProvider = (props) => {

  const showToast = ({ toastText, subText, isSuccess, closeToast }) => {
    const content = (
      <div>
        <h5 style={{ fontWeight: '700' }} className='my-auto'>{toastText}</h5>
        {isSuccess && subText && <h6>{subText}</h6>}
      </div>
    );

    const toastOptions = {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      onClose: closeToast,
    };

    if (isSuccess) {
      toast.success(content, toastOptions);
    } else {
      toast.error(content, toastOptions);
    }
  };


 


  const contextValue = { showToast
   
  };

  return (
    <AppContext.Provider value={contextValue}>{props.children}
   
    </AppContext.Provider>
  );
}


export const useAppContext = () => {
  return useContext(AppContext);
};




