import React, {useState, useEffect, useRef} from 'react'
import { Button,Card, Row, Col, Image, Form, Carousel } from 'react-bootstrap'
import arrowGiphy from '../assets/arrow-giphy.svg'
import arrowRight from '../assets/arrow-right.svg'
import technologyGiphy from '../assets/technology-giphy.svg'
import quote from '../assets/quote.svg'
import giphy from '../assets/giphy-3.svg'
import boxBg from '../assets/box-background.png'
import idea1 from '../assets/reality-icon-1.svg'
import idea2 from '../assets/reality-icon-2.svg'
import idea3 from '../assets/reality-icon-3.svg'
import phone1 from '../assets/overlay-phone1.svg'
import phone2 from '../assets/overlay-phone2.svg'
import laptop1 from '../assets/laptop1.svg'
import laptop2 from '../assets/laptop2.svg'
import virtue from '../assets/virtueshop-logo.svg'
import stock from '../assets/stockit-logo.svg'
import roppi from '../assets/roppi.svg'
import faramove from '../assets/faramove.svg'
import legalPedia from '../assets/Legalpedia.jpg'
import wave1 from '../assets/wave.png'
import wave2 from '../assets/wave2.png'
import wave3 from '../assets/wave3.svg'
import wave4 from '../assets/wave4.svg'
import tool1 from '../assets/tool-1.svg'
import tool2 from '../assets/tool-2.svg'
import tool3 from '../assets/tool-3.svg'
import client from '../assets/ai-image1.svg'
import client2 from '../assets/ai-image2.svg'
import client3 from '../assets/ai-image3.svg'
import rate from '../assets/Rate.svg'
import advantage1 from '../assets/advantage-icon1.svg'
import advantage2 from '../assets/advantage-icon2.svg'
import advantage3 from '../assets/advantage-icon3.svg'
import dami from '../assets/damilola.jpg'
import sarah from '../assets/sarah.jpeg'
import dunsimi from '../assets/dunsimi.png'
import caroline from '../assets/caroline.jpg'
import malebit from '../assets/male-bitmoji.png'
import toyosi from '../assets/toyo-3.png'
import olanike from '../assets/olanike-bit.png'
import fara from '../assets/fara-bitmoji.png'
import eliza from '../assets/eliza.jpg'
import daniel from '../assets/daniel.jpg'
import david from '../assets/david.jpg'
import damisi from '../assets/damisi.jpg'
import { useAppContext } from '../AppContext'
import emailjs from 'emailjs-com';
import Footer from '../components/Footer'


const tools = [
    {
    id:1,
    title:'Software Development',
    icon:tool1,
    text:'Crafting custom solutions as sleek as smartphones and powerful as a lioness fuels our Devs desires.'
    },
    {
        id:2,
        title:'Web design',
        icon:tool2,
        text:"We design captivating websites that are as friendly as your grandma (we love grandmas!)"
        },
        {
            id:3,
            title:'App Development',
            icon:tool3,
            text:"Our tush developers create killer mobile apps that make your brand stickier than a squeaky toy."
            },
            {
                id:4,
                title:'Product Management',
                icon:tool1,
                text:'We have PM rockstars that nurture your product to success faster than you can say, "boom!"'
                },
                {
                    id:5,
                    title:'Human Resources',
                    icon:tool2,
                    text:'Our HR wizards attract top talents with skills that blast your business to new heights.'
                    },
                    {
                        id:6,
                        title:'Business Analysis',
                        icon:tool3,
                        text:'Our Analyst ninjas use data-driven decisions and insights to optimize operations and conquer your goals'
                        },
]

const teams = [
    // {
    // id:1,
    // title:'CEO',
    // icon:client,
    // name:'Sarah Oshunniyi'
    // },
    {
      id:1,
      title:'Product Manager',
      icon:fara,
      name:"Far'hah Alade-Raji"
      },
    
        {
            id:2,
            name:'Oteje Elizabeth',
            icon:eliza,
            title:"Frontend Engineer"
            },
            {
              id:3,
              title:'Backend Engineer',
              icon:daniel,
              name:"Daniel Adejare"
              },
            {
                id:4,
                name:'Caroline Adegbola',
                icon:caroline,
                title:'UI/UX Designer'
                },

                       
                          {
                            id:5,
                            title:'Mobile Engineer',
                            icon:david,
                            name:"David Ajibade"
                            },
                            {
                              id:6,
                              title:'Operations Manager',
                              icon:olanike,
                              name:'Toyosi Atunrase'
                              },
                            {
                              id:7,
                              title:'Backend Engineer',
                              icon:malebit,
                              name:"Yusuf Mustahan "
                              },
                              
                                {
                                  id:8,
                                  title:'Social Media Manager',
                                  icon:dami,
                                  name:'Damilola Agbetuyi'
                                  },
                              
]

const testimonies = [
    {
    id:1,
    title:"Virtue's Shop CEO",
    image:sarah,
    name:'Sarah Oshunniyi',
    companyLogo:virtue,
    testimonyTitle:'Idea to Product',
    testimony:'I approached SalesWave with an idea, and our collaboration resulted in a successful product. They are excellent communicators and provide high-quality service.'
    },
    {
        id:2,
        title:"Director",
        image:client2,
        name:'Rachelle Egbhodaghe',
        companyLogo:legalPedia,
        testimonyTitle:'Talent Recruitment',
        testimony:"I recently worked with SalesWave on a project for Legalpedia Nigeria Ltd, and I couldn't be happier with their work. They were always on time with every part of the project and met all the deadlines without any issues.<br/>What really impressed me was how they went above and beyond to manage the project. They didn’t just do what was asked—they took the time to understand our goals and even added extra value that we didn’t expect. Their attention to detail and clear communication made everything run smoothly.<br/>Working with SalesWave felt like having a true partner who cared about the project's success as much as we did at Legalpedia Nigeria Ltd. I highly recommend them to anyone looking for a reliable and professional development agency. Thank you, SalesWave, for making this a great experience!"
        },
       
]

const projects = [
  {
      
      logoSrc: virtue,
      link: 'https://app.virtuesshop.co/',
      linkText: "Virtues' Shop",
  },{
   
    logoSrc: roppi,
    link: 'https://roppi.co/',
    linkText: "Roppi"
 

  
    
  },
  {
      
      logoSrc: stock,
      link: '',
      linkText: "StockIt",
  },{
  
    
    logoSrc: faramove,
    link: 'https://faramove.com/',
    linkText: "Faramove"

  },
  
];

const MAX_WORDS = 30;

const TestimonyCard = ({ testimony }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Split the testimony into words
  const words = testimony.testimony.split(' ');

  // Truncate testimony if it's longer than MAX_WORDS
  const truncatedTestimony = words.length > MAX_WORDS
    ? words.slice(0, MAX_WORDS).join(' ') + '...'
    : testimony.testimony;

  // Handle line breaks for full testimony
  const formattedTestimony = testimony.testimony.replace(/\n/g, '<br/>');

  return (
    <Col xs={12} md={6} className='mb-4 px-lg-4'>
      <Card style={{ backgroundColor: '#1B1B1B' }} className='p-3 p-lg-4 h-100'>
        <div className='d-flex flex-column gap-3 gap-md-4'>
        <img src={quote} alt='quote' style={{ width: "70px", height: '70px' }} />
        <div>
        <h3 style={{ color: '#E9EBF8', fontWeight: '400' }}>
          {/* Show truncated or full testimony based on state */}
          {!isExpanded
            ? truncatedTestimony
            : <span dangerouslySetInnerHTML={{ __html: formattedTestimony }} />}
        </h3>
        
        {/* Show "Read more" or "Show less" based on state */}
        {words.length > MAX_WORDS && (
          <span onClick={() => setIsExpanded(!isExpanded)} style={{ color: '#E9EBF8', cursor: 'pointer' }}>
            {isExpanded ? 'Show less..' : 'Read more..'}
          </span>
        )}
        </div>
        <div className='d-none d-md-flex justify-content-between'>
          <div className='d-flex gap-3 align-items-center mt-2'>
            <img src={testimony?.companyLogo} alt='logo' style={{ borderRadius: '50%', width: '80px', height: '80px' }} />
            <div>
              <h4 style={{ color: '#E9EBF8', fontWeight: '800' }}>{testimony?.name}</h4>
              <h6 style={{ color: '#E9EBF8', fontWeight: '500' }}>{testimony?.title}</h6>
            </div>
          </div>
          <img src={rate} alt='rate' />
        </div>
       
          <div className='d-flex d-md-none  gap-3 align-items-center mt-2'>
            <img src={testimony?.companyLogo} alt='logo' style={{ borderRadius: '50%', width: '80px', height: '80px' }} />
            <div>
              <h4 style={{ color: '#E9EBF8', fontWeight: '800' }}>{testimony?.name}</h4>
              <h6 style={{ color: '#E9EBF8', fontWeight: '500' }}>{testimony?.title}</h6>
              <img src={rate} alt='rate' />
            </div>
          </div>
         
        </div>
        
      </Card>
    </Col>
  );
};


const Home = () => {

    const headers = [
        'Launch Your Software Dreams With SalesWave',
        'Catch the Innovation Wave Today',
        'Propel your Ideas from Brainstorm to Success'
      ];
    

      const [loading, setLoading] = useState(false);
    const {showToast}= useAppContext()
      const [index, setIndex] = useState(0);
      const [displayedText, setDisplayedText] = useState('');
      const [typing, setTyping] = useState(true);
      const [currentIndex, setCurrentIndex] = useState(0);
      const [currentTestimonyIndex, setCurrentTestimonyIndex] = useState(0);
      const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

      useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 576);
        };
     
        window.addEventListener('resize', handleResize);
     
        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
      }, []);
   
      useEffect(() => {
        if (typing) {
          if (displayedText.length < headers[index].length) {
            const timeoutId = setTimeout(() => {
              setDisplayedText(headers[index].slice(0, displayedText.length + 1));
            }, 100);
            return () => clearTimeout(timeoutId);
          } else {
            const timeoutId = setTimeout(() => {
              setTyping(false);
            }, 2000); // Wait for some time before clearing the text
            return () => clearTimeout(timeoutId);
          }
        } else {
          const timeoutId = setTimeout(() => {
            setDisplayedText('');
            setIndex((index + 1) % headers.length);
            setTyping(true);
          }, 1000); // Delay before starting the next header
          return () => clearTimeout(timeoutId);
        }
      }, [displayedText, typing, index, headers]);

    

      const scrollToSection = () => {
        const section = document.getElementById('section5');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
      const handleNext = () => {
        if(isMobile){
        setCurrentIndex((prevIndex) => (prevIndex >= Math.ceil(teams.length / 3) - 1 ? 0 : prevIndex + 1));}
        else{
          setCurrentIndex((prevIndex) => (prevIndex >= Math.ceil(teams.length / 4) - 1 ? 0 : prevIndex + 1));}
        
      };
    
      const handlePrevious = () => {
        if(isMobile){
          setCurrentIndex((prevIndex) => (prevIndex <= 0 ? Math.ceil(teams.length / 3) - 1 : prevIndex - 1));}
          else{
            setCurrentIndex((prevIndex) => (prevIndex <= 0 ? Math.ceil(teams.length / 4) - 1 : prevIndex - 1));
          }
      
      };
    
      const startIndex = isMobile ? currentIndex * 3 : currentIndex * 4;
      const endIndex = isMobile ? Math.min(startIndex + 3, teams.length) : Math.min(startIndex + 4, teams.length);
    
      const visibleTeams = teams.slice(startIndex, endIndex);


      const handleNextTestimony = () => {
        if(isMobile){
          setCurrentTestimonyIndex((prevIndex) => (prevIndex >= Math.ceil(testimonies.length / 1) - 1 ? 0 : prevIndex + 1));}
          else{
            setCurrentTestimonyIndex((prevIndex) => (prevIndex >= Math.ceil(testimonies.length / 2) - 1 ? 0 : prevIndex + 1));}
          }
      
    
      const handlePreviousTestimony = () => {
        if(isMobile){
          setCurrentTestimonyIndex((prevIndex) => (prevIndex <= 0 ? Math.ceil(testimonies.length / 1) - 1 : prevIndex - 1));}
          else{
            setCurrentTestimonyIndex((prevIndex) => (prevIndex <= 0 ? Math.ceil(testimonies.length / 2) - 1 : prevIndex - 1));
          }
      };
    
      
      const startTestimonyIndex = isMobile ? currentTestimonyIndex * 1 : currentTestimonyIndex * 2;
      const endTestimonyIndex = isMobile ? Math.min(startTestimonyIndex + 1, testimonies.length) : Math.min(startTestimonyIndex + 2, testimonies.length);
    
      const currentTestimony = testimonies.slice(startTestimonyIndex, endTestimonyIndex);
      // const currentTestimony = testimonies[currentTestimonyIndex];



    const sendEmail = (e) => {
      e.preventDefault();

      setLoading(true)
  
      const formData = new FormData(e.target);
      const templateParams = {
        from_name: formData.get('name'),
        from_email: formData.get('email'),
        subject: formData.get('subject'),
        message: formData.get('message'),
      };
  
      emailjs
        .send('service_qz0n8ka', 'template_e5w27pg', templateParams, 'OWGDWJiOiLcjPehLx')
        .then((response) => {
          setLoading(false)
        
          showToast({toastText:'Message sent successfully', isSuccess:true})
          e.target.reset();
         
        })
        .catch((error) => {
          setLoading(false)
       
          showToast({toastText:'Failed to send message. Please try again', isSuccess:false})

        });
        
    };
  return (
    <div style={{overflowX:'hidden'}} className='mb-0 pb-0' >
    <div className="scrollspy-content">
     
        <section className='py-5 position-relative' id='section0' >
        <div className='gradient-patch-1' />
        
        <div className='gradient-patch-2' />
            <div className='gradient-patch-3' />
            <img src={arrowGiphy} alt='giphy'className='position-absolute arrow-giphy' style={{
angle: '173.05 deg', pointerEvents: 'none', 
}}/>
            <Card style={{backgroundColor:'transparent', border:'none', borderRadius:'0px', color:'white'}}>
            <Card.Img src={boxBg} alt='' className='box-bg'/>
       
<Card.ImgOverlay className='pt-3 pt-md-5'>
        <div className='d-flex flex-column justify-content-center text-center gap-3 mx-auto mt-5 pt-5' style={{maxWidth:'43rem'}}>
            {/* <h1 className='gradient-text gradient-text-change mt-3'>{headerText}
            </h1> */}
             <h1 className='gradient-text mt-lg-3'>
      {displayedText}<span className="cursor">|</span>
    </h1>
            <h3 className='px-lg-5 px-2' style={{fontWeight:'500'}}>We are your partner in crafting next gen solutions that sail your business to the next level.
            </h3>
            <div className='mt-3' id="navbar-example2">
            <Button className='btn btn-primary ' onClick={scrollToSection}>Ride the Wave with us</Button></div>
            

        </div>
        </Card.ImgOverlay>
        

<section  className='position-relative hero-section-2 pt-4 pt-lg-0' >
<img src={technologyGiphy} className='position-absolute technology-giphy'alt='giphy' style={{
angle: '173.05 deg', pointerEvents: 'none', 
}}/>
<div className='px-5 ' >
            <h2 className='text-center'>From Ideas to Reality</h2>
            <Row className='mt-5'>
                <Col xs={12} md={4} className='mb-4'>
                <div style={{
            padding: '1px',
            background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
            borderRadius: '13px' 
        }} className='h-100'>
            <Card style={{
                    backgroundColor: "#1B1B1B",
                    borderRadius: '12px', 
                   
                }} className='p-3 p-md-5 d-flex flex-column gap-4 text-center justify-content-center text-white h-100'>
                        <div className='d-flex justify-content-center mx-auto' style={{borderRadius:'50%', backgroundColor:'white', width:'48px', height:'48px'}}>
                            <img src={idea1} alt=''/>
                        </div>
                        <h3>Collaborate & Plan</h3>
                        <h5 className='mb-4' style={{fontWeight:'400'}}>We listen to your vision, understand your needs, and craft custom solutions that perfectly align with your goals.
</h5>

                    </Card>
                    </div>
                </Col>
                <Col xs={12} md={4} className='mb-4'>
                <div className='h-100' style={{
            padding: '1px',
            background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
            borderRadius: '13px' 
        }}>
            <Card style={{
                    backgroundColor: "#1B1B1B",
                    borderRadius: '12px', 
                   
                }}className='p-3 p-md-5 d-flex flex-column gap-4 text-center justify-content-center text-white h-100'>
                        <div className='d-flex justify-content-center mx-auto' style={{borderRadius:'50%', backgroundColor:'white', width:'48px', height:'48px'}}>
                            <img src={idea2} alt=''/>
                        </div>
                        <h3>Build & Refine</h3>
                        <h5 className='mb-4' style={{fontWeight:'400'}}>Our expert developers and designers bring your vision to life, utilizing cutting-edge technology to create a secure, user-friendly solution. 
</h5>

                    </Card>
                    </div>
                </Col>
                <Col xs={12} md={4} className='mb-4'>
                <div className='h-100' style={{
            padding: '1px',
            background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
            borderRadius: '13px' 
        }}>
            <Card style={{
                    backgroundColor: "#1B1B1B",
                    borderRadius: '12px', 
                   
                }}className='p-3 p-md-5 d-flex flex-column gap-4 text-center justify-content-center text-white h-100'>
                        <div className='d-flex justify-content-center mx-auto' style={{borderRadius:'50%', backgroundColor:'white', width:'48px', height:'48px'}}>
                            <img src={idea3} alt=''/>
                        </div>
                        <h3>Launch & Grow</h3>
                        <h5 className='mb-4' style={{fontWeight:'400'}}>We ensure a smooth launch and offer ongoing support to keep your product evolving and generating profits as your business scales.
</h5>

                    </Card>
                    </div>
                </Col>
            </Row>
        </div>
        
</section>
</Card>
       
        </section>
       
      <section style={{backgroundColor:'#1B1B1B'}} className='py-5 px-3 px-lg-5 position-relative'>
        <img src={wave1} className='position-absolute wave1' style={{angle: '-38.27 deg', pointerEvents: 'none',}}/>
        <img src={wave1} className='position-absolute wave' style={{ transform:'scaleY(1)',pointerEvents: 'none',}}/>
        <img src={wave2} className='position-absolute wave2' style={{angle: '152.92 deg', pointerEvents: 'none',}}/>
      <h1 className='text-center gradient-text mt-lg-5 mb-4' id="section1">Projects We've Shipped
            </h1>

            <Row className='mt-md-5 mb-3 d-none d-md-flex'>
           
                <Col xs={3} className='h-100'>
                <div className='d-flex flex-column gap-3 text-align-center align-items-center  h-100 justify-content-denter text-center'>
                 
<Image fluid src={virtue} alt='project' style={{width:"200px", height:'150px'}} className='' />
<h3 className='mt-1 header-center'>
<a href='https://app.virtuesshop.co/' target="_blank" rel="noopener noreferrer" className=" gradient-text-underline ">
Virtues' Shop
</a>
</h3>


                </div>
            </Col>
            <Col xs={3} className='h-100'>
                <div className='d-flex flex-column gap-3 text-align-center align-items-center  h-100 justify-content-denter text-center'>
                 
<Image fluid src={roppi} alt='project' style={{width:"150px", height:'150px'}} className='' />
<h3 className='mt-1 header-center'>
<a href='https://roppi.co/' target="_blank" rel="noopener noreferrer" className=" gradient-text-underline ">
Roppi
</a>
</h3>


                </div>
            </Col>
            <Col xs={3} className='h-100'>
                <div className='d-flex flex-column gap-3 text-align-center align-items-center  h-100 justify-content-denter text-center'>
                 
<Image fluid src={stock} alt='project' style={{width:"150px", height:'150px'}} className='' />
<h3 className='mt-1 header-center'>
<a href='' target="_blank" rel="noopener noreferrer" className=" gradient-text-underline ">
StockIt
</a>
</h3>


                </div>
            </Col>
            <Col xs={3} className='h-100'>
                <div className='d-flex flex-column gap-3 text-align-center align-items-center  h-100 justify-content-denter text-center'>
                 
<Image fluid src={faramove} alt='project' style={{width:"150px", height:'150px'}} className='' />
<h3 className='mt-1 header-center'>
<a href='https://faramove.com/' target="_blank" rel="noopener noreferrer" className=" gradient-text-underline ">
Faramove
</a>
</h3>


                </div>
            </Col>
                      
                       
                    </Row>
                    <Row className='mt-md-5 mb-3 d-flex d-md-none'>
           
           <Col xs={3} className='h-100'>
           <div className='d-flex flex-column gap-3 text-align-center align-items-center  h-100 justify-content-denter text-center'>
            
<Image fluid src={virtue} alt='project' style={{width:"120px", height:'70px', objectFit:'cover'}} className='' />
<h6 className='mt-1 text-center'>
<a href='https://app.virtuesshop.co/' target="_blank" rel="noopener noreferrer" className=" gradient-text-underline ">
Virtue's Shop
</a>
</h6>


           </div>
       </Col>
       <Col xs={3} className='h-100'>
           <div className='d-flex flex-column gap-3 text-align-center align-items-center  h-100 justify-content-denter text-center'>
            
<Image fluid src={roppi} alt='project' style={{width:"70px", height:'70px'}} className='' />
<h6 className='mt-1 text-center'>
<a href='https://roppi.co/' target="_blank" rel="noopener noreferrer" className=" gradient-text-underline ">
Roppi
</a>
</h6>


           </div>
       </Col>
       <Col xs={3} className='h-100'>
           <div className='d-flex flex-column gap-3 text-align-center align-items-center  h-100 justify-content-denter text-center'>
            
<Image fluid src={stock} alt='project' style={{width:"70px", height:'70px'}} className='' />
<h6 className='mt-1 text-center'>
<a href='' target="_blank" rel="noopener noreferrer" className=" gradient-text-underline ">
StockIT
</a>
</h6>


           </div>
       </Col>
       <Col xs={3} className='h-100'>
           <div className='d-flex flex-column gap-3 text-align-center align-items-center  h-100 justify-content-denter text-center'>
            
<Image fluid src={faramove} alt='project' style={{width:"70px", height:'70px'}} className='' />
<h6 className='mt-1 text-center'>
<a href='https://faramove.com/' target="_blank" rel="noopener noreferrer" className=" gradient-text-underline ">
Faramove
</a>
</h6>


           </div>
       </Col>
                 
                  
               </Row>
           
            {/* {projects.map((project, index) => (
                <div
                    key={index}
                    style={{
                        display: index === currentProjectIndex ? 'block' : 'none',
                     
                        transition: 'opacity 0.5s ease-in-out',
                        transform: `translateX(${(index - currentProjectIndex) * 100}%)`,
                        opacity: index === currentProjectIndex ? 1 : 0,
                    }}
                >
                    <Row className='mt-md-5 mb-3'>
                        <Col xs={6} className='h-100'>
                            <div className='d-flex flex-column gap-3 text-align-center px-2 px-lg-4 h-100'>
                                <Image fluid src={project.phoneImageSrc} alt={project.phoneAlt} style={{ maxHeight: '407px', objectFit:'cover' }} />
                                <div
  className='d-block text-center gap-2'
  
>
    <Image fluid src={project.logoSrc} alt={project.logoAlt} style={{ width: '50px', height: '50px', cursor:'pointer', objectFit:'cover' }} onClick={() => window.open(project.link2, '_blank', 'noopener,noreferrer')}
  onKeyPress={(e) => { if (e.key === 'Enter') window.open(project.link2, '_blank', 'noopener,noreferrer'); }}
  role="link"
  tabIndex="0"  />
    <h3 className='mt-1'>
        <a href={project.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none"   style={{ color: '#E9EBF8' }}>
            {project.linkText}
        </a>
    </h3>
</div>

                            </div>
                        </Col>
                        <Col xs={6} className='h-100'>
                            <div className='d-flex flex-column gap-4 text-align-center px-2 px-lg-4 h-100 mt-4 pt-0 pt-md-3 pt-xl-0 mt-md-5'>
                                <Image fluid src={project.laptopImageSrc} alt={project.laptopAlt} style={{ maxHeight: '500px',objectFit:'cover' }} />
                                <div className='d-block text-center gap-2 mt-2 mt-lg-2 mt-xl-0 pt-0 pt-md-4 pt-xl-0 ' 
  style={{ cursor: "pointer" }}>
                                    <Image fluid src={project.logo2Src} alt={project.logo2Alt} style={{ width: '50px', height: '50px', cursor:'pointer' }} onClick={() => window.open(project.link2, '_blank', 'noopener,noreferrer')}
  onKeyPress={(e) => { if (e.key === 'Enter') window.open(project.link2, '_blank', 'noopener,noreferrer'); }}
  role="link"
  tabIndex="0" />
                                    <h3 className='mt-1' style={{ cursor: "pointer" }}>
                                        <a href={project.link2} target="_blank" rel="noopener noreferrer" className="text-decoration-none" style={{ color: '#E9EBF8' }}>
                                            {project.link2Text}
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ))}
           
           {projects.length > 1 &&
           <div className='d-flex justify-content-center gap-2 text-center mt-4'>
           {projects.map((_, index) => (
               <button 
                   key={index}
                   onClick={() => handleButtonClick(index)}
                   className={index === currentProjectIndex ? 'next1-buttons' : 'prev1-buttons'}
               >
                   
               </button>
           ))}
       </div>} */}
           
         

      </section>
      <section className='position-relative py-5 px-3 px-md-5'>
      <img src={technologyGiphy} className='position-absolute technology-giphy-2'alt='giphy' style={{
angle: '39.36 deg', pointerEvents: 'none', 
}}/>
<div className='pt-lg-5 pt-2'><h1 className='text-center my-md-5 my-3'id="section2"> We’ve Got The Tools You Need</h1></div>
<div className='position-relative'>
<div style={{borderBottom:'3px solid white', width:'200px'}}></div>
<Row className='my-5'>
    {tools.map((tool)=> (
        <Col xs={12} sm={6} lg={4} key={tool.id} className='mb-4 pe-lg-4'>
            <Card style={{backgroundColor:'#1B1B1B', border:'1px solid #DADADA33', borderRadius:'12px'}} className='px-3 pt-3 pb-5 d-flex flex-column gap-3 h-100'>
                <img src={tool.icon} style={{width:'48px', height:'48px'}}/>
                <h3 style={{fontFamily:'Nunito Sans',color:'#E9EBF8', textTransform:'uppercase', fontWeight:'700'}} className='mt-1'>{tool.title}</h3>
                <h6 style={{fontFamily:'Nunito Sans',color:'#E9EBF8', lineHeight:'24px', fontWeight:'400'}}>{tool.text}</h6>

            </Card>
        </Col>
    ))}
</Row>
<div style={{borderBottom:'3px solid white', width:'200px', bottom:'-12px', right:'0px', pointerEvents:'none'}} className='position-absolute'></div>
</div>

      </section>
      <section className='position-relative pt-0 pt-md-3 pt-md-5 pb-5 px-3 px-md-5'>

      <img src={technologyGiphy} className='position-absolute technology-giphy'alt='giphy' style={{left:'30px',
angle: '173.05 deg', pointerEvents: 'none', 
}}/>
  <img src={giphy} className='technology-giphy-3 position-absolute'alt='giphy' style={{ position:'absolute', pointerEvents: 'none', 
}}/>
<div className=''><h1 className='text-center gradient-text' id="section3"> Client's Success Stories</h1>
<div className='mt-4 mt-md-5 position-relative px-lg-4 pb-3 pb-md-5'> 
{isMobile ? testimonies.length > 1 && 
<div className="d-flex gap-3 justify-content-end">
        <button
          className="prev-buttons"
          onClick={handlePreviousTestimony}
          
        >
          <img src={arrowRight} />
        </button>
        <button
          className='next-buttons'
          onClick={handleNextTestimony}
         
        >
         <img src={arrowRight} style={{transform: 'rotate(180deg)'}}/>
        </button>
      </div> :  testimonies.length > 2 && 
<div className="d-flex gap-3 justify-content-end">
        <button
          className="prev-buttons"
          onClick={handlePreviousTestimony}
          
        >
          <img src={arrowRight} />
        </button>
        <button
          className='next-buttons'
          onClick={handleNextTestimony}
         
        >
         <img src={arrowRight} style={{transform: 'rotate(180deg)'}}/>
        </button>
      </div>
}
<Row className='mt-md-5 mt-4'>
        {currentTestimony.map((testimony, index) => (
         <TestimonyCard key={testimony.id} testimony={testimony} />
        ))}
      </Row>



</div>
</div>



      </section>
      <section style={{backgroundColor:'#1B1B1B'}} className='pt-4 pt-md-5 pb-0 pb-md-5 px-3 px-lg-5 position-relative'>
        <Card className='card-container' style={{background:'transparent', border:'none',color:'white'}}>
        <Card.Img src={boxBg} className='unique-img'/>
        <Card.ImgOverlay className='pt-3 pb-5 pb-md-0 px-3 px-md-5'>
            <h1 className='text-center mb-5'>Our Unique Advantage</h1>
            <div className='d-flex justify-content-end mb-4 mb-md-5'>
            <div style={{
            padding: '1px',
            background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
            borderRadius: '13px', maxWidth:'705px'
        }} className=''>
            <Card style={{
                    backgroundColor: "#1B1B1B",
                    borderRadius: '12px', 
                   
                }} className='p-3 p-md-4 text-white'>
                    <div className='d-flex align-items-center gap-3'>
                    <Image fluid src={advantage1} style={{maxWidth:'100px', minWidth:'80px'}}/>
                    <div>
                        <h4 style={{fontWeight:'500'}}>Customized Solutions</h4>
                        <h5 style={{fontWeight:'400'}}>Our collaborative approach ensures your business solution is precisely tailored to your needs and goals.</h5>
                    </div>
                    </div>
                </Card>
                </div>
            </div>
            <div className='mb-4 mb-md-5'>
            <div style={{
            padding: '1px',
            background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
            borderRadius: '13px', maxWidth:'705px'
        }} className=''>
            <Card style={{
                    backgroundColor: "#1B1B1B",
                    borderRadius: '12px', 
                   
                }} className='p-3 p-md-4 text-white'>
                    <div className='d-flex align-items-center gap-3'>
                    <Image fluid src={advantage2} style={{maxWidth:'100px', minWidth:'80px'}}/>
                    <div>
                        <h4 style={{fontWeight:'500'}}>Deep Expertise</h4>
                        <h5 style={{fontWeight:'400'}}>We speak the language of your industry. Whether you're in e-commerce, healthcare, or finance, our deep understanding translates into solutions that perfectly fit your unique needs.</h5>
                    </div>
                    </div>
                </Card>
                </div>
            </div>
            <div className='d-flex justify-content-end mb-4 mb-md-0'>
            <div style={{
            padding: '1px',
            background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
            borderRadius: '13px', maxWidth:'705px'
        }} className=''>
            <Card style={{
                    backgroundColor: "#1B1B1B",
                    borderRadius: '12px', 
                   
                }} className='p-3 p-md-4  text-white'>
                    <div className='d-flex align-items-center gap-3'>
                    <Image fluid src={advantage3} style={{maxWidth:'100px', minWidth:'80px'}}/>
                    <div>
                        <h4 style={{fontWeight:'500'}}>Fast and Flexible</h4>
                        <h5 style={{fontWeight:'400'}}>Our agile approach ensures we stay ahead of the curve, delivering solutions quickly and efficiently so you can capitalize on new opportunities..</h5>
                    </div>
                    </div>
                </Card>
                </div>
            </div>
        </Card.ImgOverlay>
        </Card>
      </section>
      <section className='py-md-5 py-4 px-3 px-lg-5 position-relative team'>
        <h1 className='gradient-text text-center' id="section4"> Meet The Team</h1>
        <Row className='mt-md-5 mt-4'>
        {visibleTeams.map((team, index) => (
          <Col key={team.id} xs={4} md={3} className='mb-4 px-lg-4 h-100'>
            <Card style={{backgroundColor:'#1B1B1B', border:'1px solid #DADADA33', }}className='d-none d-md-block h-100 p-0'>
              <Card.Img src={team.icon} style={{height:'300px',width:'100%'}} className=''/>
              <Card.ImgOverlay className='p-0 d-flex align-items-end justify-content-end'>
                <div style={{ backgroundColor: '#1B1B1B', border: '1px solid #DADADA33', width:'210px' }} className='p-3'>
                  <h4 style={{ fontFamily: 'Nunito Sans', fontWeight: '700', color: '#E9EBF8' }}>{team.name}</h4>
                  <h5 style={{ fontFamily: 'Nunito Sans', fontWeight: '400', color: '#E9EBF8' }} className='mt-2'>{team.title}</h5>
                </div>
              </Card.ImgOverlay>
            </Card>
            <Card style={{border:'none', background:'transparent'}} className='d-block d-md-none h-100'>
              <Card.Img src={team.icon} style={{height:'135px', backgroundColor:'#1B1B1B', border:'1px solid #DADADA33', borderRadius:'12px', objectFit:'cover'}} className=''/>
              <div className='mt-1'>
              <p className='d-flex d-md-none mb-0 pb-0 px-0' style={{ fontFamily: 'Nunito Sans', fontWeight: '600', color: '#E9EBF8' }}>{team.name}</p>
              <p className='d-flex d-md-none mt-0 pt-1'  style={{ fontFamily: 'Nunito Sans', fontWeight: '400', color: '#E9EBF8', fontSize:'10.5px' }} >{team.title}</p>
                  <h6 className='d-none d-md-flex' style={{ fontFamily: 'Nunito Sans', fontWeight: '700', color: '#E9EBF8' }}>{team.name}</h6>
                  <p className='d-none d-md-flex mt-2'  style={{ fontFamily: 'Nunito Sans', fontWeight: '400', color: '#E9EBF8' }} >{team.title}</p>
              
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="d-flex gap-3 text-center justify-content-center mt-md-3">
        <button
          className="prev-buttons"
          onClick={handlePrevious}
          
        >
          <img src={arrowRight} />
        </button>
        <button
          className='next-buttons'
          onClick={handleNext}
         
        >
         <img src={arrowRight} style={{transform: 'rotate(180deg)'}}/>
        </button>
      </div>
      </section>
      <section className='py-md-5 py-4 px-3 px-lg-5 position-relative' style={{backgroundColor:'#1B1B1B'}} id="section5">
     
      <img src={wave4} className='position-absolute wave3' style={{position:'absolute', bottom:'0', left:0,  pointerEvents: 'none', }}/>
      <img src={wave3} className='position-absolute wave3' style={{ position:'absolute', right:'0', top:'0',  pointerEvents: 'none', }}/>
      <h1 style={{maxWidth:'45rem'}} >Let's Build Something Amazing</h1>
      {loading ? 
    (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-secondary" role="status">
    <span className="visually-hidden">Loading...</span>
    </div>
    </div>):

<Form className='my-5 text-white pb-lg-3 position-relative' onSubmit={sendEmail}>
  <Row>
    <Col xs={12} md={6}>
      <div style={{
        padding: '1px',
        background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
        borderRadius: '8px',
        
      }} className='mb-4'>
        <Form.Control name="name" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Name' className='py-3' required />
      </div>
      <div style={{
        padding: '1px',
        background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
        borderRadius: '8px',
        
      }} className='mb-4'>
        <Form.Control name="email" required style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Email address' type='email' className='py-3' />
      </div>
      <div style={{
        padding: '1px',
        background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
        borderRadius: '8px',
        
      }} className='mb-4 mb-md-0'>
      
        <Form.Select name="subject" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} className='py-3' required>
          <option value="" hidden> Subject</option>
          <option value="Enquiry">Enquiry</option>
          <option value="Careers">Careers</option>
        </Form.Select>
      </div>
    </Col>
    <Col xs={12} md={6}>
      <div style={{
        padding: '1px',
        background: 'linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%)',
        borderRadius: '8px',
        
      }} className='mb-4 h-100'>
        <textarea name="message" style={{ backgroundColor: '#1B1B1B', borderRadius: '7px', border: 'none', color: 'white' }} placeholder='Message' type='text' className='py-3 w-100 h-100' required></textarea>
      </div>
    </Col>
  </Row>
  <div className='d-flex justify-content-end mt-4 pe-5 me-lg-5'>
    <Button type="submit">Let's Chat</Button>
  </div>

</Form>
}

      </section>
      
    </div>
   
    {/* <style>
{`
  .custom-carousel .carousel-indicators button {
    width: 52px !important; 
    background: #4E4F56 !important;
  color: black !important;
  height: 7px !important;
  border: none !important;
  padding: 0 !important;
  }
  .custom-carousel .carousel-indicators .active {
    background: linear-gradient(95.21deg, #406282 13.07%, #FFB74D 112.55%) !important;
  color: black !important;
width: 26px !important;
  height: 7px !important;
  border: none !important;
  padding: 0 !important;
  }
  .custom-carousel .carousel-indicators {
    bottom: -30px !important;
  }

  .custom-carousel .carousel-item {
    border-bottom: none !important;
  }
  .custom-carousel .carousel-inner {
    border-bottom: none !important;
  }
`}
</style> */}
    </div>
  )
}

export default Home